<template>
  <div class="footer">Copyright &copy; {{ year }} Ismail Omar Dev</div>
</template>
<script>
export default {
  data() {
    return { year: "" };
  },
  beforeMount() {
    const d = new Date();
    this.year = d.getFullYear();
  },
};
</script>
<style scoped>
.footer {
  height: 50px;
  width: 100%;
  background: #2c3e50;
  color: white;
  line-height: 50px;
  /* position: absolute;
    bottom: 0px; */
}
</style>