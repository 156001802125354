<template>
    <w-table :headers="table.headers" :items="table.items" fixed-headers style="height:50vh" fixed-footer="true">
          <template #no-data>
            <img src="assets/img/empty-box.png" alt="">
          </template>
          <template #footer>
            <w-flex justify-space-between>
              <!-- <w-button rounded sm ><w-icon>wi-plus</w-icon> add data</w-button> -->
              <span></span>
              <w-button sm bg-color="success">{{ table.items.length }} Record</w-button>
            </w-flex>
          </template>
      </w-table>
</template>
<script>
export default {
    data() {
        return {
            table: {
                items: [
                // {id:1,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:2,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:3,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:4,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:5,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:6,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:7,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:8,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:9,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:10,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:11,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:12,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"},
                // {id:13,name:"Ismail Omar",address:"Fuoni",phone:"+255744667692"}
                ],
                headers: [
                {label:'#',key: "id"},
                {label:'Fullname',key: "name"},
                {label:'Address',key: "address"},
                {label:'Phone',key: "phone"}
                ],
            }
        }
    },

}
</script>