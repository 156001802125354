<template>
  <header>
    <div class="container">
      <div class="row destacados">
        <div class="col-md-4 block">
          <div class="box1">
            <img
              src="assets//img/profile.jpeg"
              class="rounded-circle img-thumbnail"
            />
            <br />
            <br />

            <h3>Ismail Omar</h3>
            <h5 style="color: #a9aba6">Software Developer</h5>
          </div>
        </div>
        <div class="col-md-8 text-left block">
          <div class="box2 text-center">
            <a
              @click="socials('facebook')"
              href="https://www.facebook.com/people/Ismailar-Omar/100008459071349/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="btn btn-outline-info rounded-circle icon"
                ><i class="icon-social-facebook"></i></span
            ></a>
            <a
              @click="socials('twitter')"
              href="https://twitter.com/ismailomardev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="btn btn-outline-info rounded-circle icon"
                ><i class="icon-social-twitter"></i></span
            ></a>
            <a
              @click="socials('instagram')"
              href="https://www.instagram.com/ismailomardev/"
              target="_blank"
              rel="noopener noreferrer"
              ><span class="btn btn-outline-info rounded-circle icon"
                ><i class="icon-social-instagram"></i></span
            ></a>
            <a
              @click="socials('linkedin')"
              href="https://www.linkedin.com/in/ismail-omar-b0b327140/"
              target="_blank"
              rel="noopener noreferrer"
              ><span class="btn btn-outline-info rounded-circle icon"
                ><i class="icon-social-linkedin"></i></span
            ></a>
            <a
              @click="socials('stackoverflow')"
              href="https://stackoverflow.com/users/8810525/ismail-omar"
              target="_blank"
              rel="noopener noreferrer"
              ><span
                class="btn btn-outline-info rounded-circle icon"
                style="overflow: hidden; padding: 0px; padding-top: 6px"
                ><img
                  style="width: 18px"
                  src="assets//img/stackoverflow.svg"
                  alt="" /></span
            ></a>
            <a
              @click="socials('github')"
              href="https://github.com/ismailomardev"
              target="_blank"
              rel="noopener noreferrer"
              ><span class="btn btn-outline-info rounded-circle icon"
                ><i class="icon-social-github"></i></span
            ></a>
            <a
              @click="socials('youtube')"
              href="https://www.youtube.com/@ismailomardev"
              target="_blank"
              rel="noopener noreferrer"
              ><span class="btn btn-outline-info rounded-circle icon"
                ><i class="icon-social-youtube"></i></span
            ></a>
            <a
              @click="socials('playstore')"
              href="https://play.google.com/store/apps/dev?id=5447915099177452623"
              target="_blank"
              rel="noopener noreferrer"
              ><span
                class="btn btn-outline-info rounded-circle icon"
                style="overflow: hidden; padding: 0px; padding-top: 6px"
                ><img
                  style="width: 18px"
                  src="assets//img/playstore.png"
                  alt="" /></span
            ></a>
          </div>
          <div class="box2">
            <p>
              <span class="icon-envelope text-info"></span
              ><span class="pl-3">developer@zanserve.com</span>
            </p>
            <p>
              <span class="icon-phone text-info"></span
              ><span class="pl-3"
                >+255 744 667 692
                <span class="text-info">( whatsapp / sms / call )</span></span
              >
            </p>
            <p>
              <span class="icon-globe text-info"></span
              ><span class="pl-3">www.developer.zanserve.com</span>
            </p>
          </div>
          <div class="box2 row pb-5 px-0">
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">Php</span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">Python</span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">Javascript</span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">Java</span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">Flutter</span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">Dart</span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">C Programming</span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">C++</span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="text-info">
                <span class="icon-check text-info"></span
                ><span class="pl-3">Golang (GO)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
export default {
  // data() {},
  methods: {
    socials(title) {
      this.setAny(title + " clicked", "social");
      this.analysis();
    },
    setAny(act, xyz) {
      var c = localStorage.getItem("ccode") ?? null;
      if (c != null) {
        const obj = {
          act: act,
          xyz: xyz,
          spa: uuidv4(),
        };
        var d = localStorage;
        var list = this.getAny();
        list.push(obj);
        d.setItem("any", JSON.stringify(list));
      }
    },
    getAny() {
      var d = localStorage;
      var any = d.getItem("any");
      var list = [];
      if (any == null) {
        d.setItem("any", JSON.stringify(list));
      } else {
        list = JSON.parse(d.getItem("any"));
      }

      return list;
    },
    visitors(c) {
      axios
        .post("", { p: "v", code: c })
        .then((response) => {
          const res = response.data;
          if (res.success == 1) {
            localStorage.setItem("ccode", c);
            this.setAny("Visit portfolio", "visit");
            this.analysis();
            // this.analysis();
            console.log("VISITOR RESPONSE : " + res.message);
          }
        })
        .catch((err) => {
          this.setAny("Visitor init fail : " + err.message, "visitorerror");
          this.analysis();
        });
    },
    analysis() {
      const from = localStorage.getItem("ccode") ?? null;
      const obj = { p: "a", from: from, data: this.getAny() };
      // console.log("DATA TO SEND : " + JSON.stringify(obj));
      axios
        .post("", obj)
        .then((response) => {
          const res = response.data;
          if (res.success == 1) {
            console.log("ANY RESPONSE : " + res);
          } else if (res.success == -1) {
            console.log("ANY RESPONSE : " + res.message);
            this.visitors(obj.from);
          } else if (res.success == 0) {
            var list = this.getAny();
            for (var i = 0; i < res.errors.length; i++) {
              var d = res.errors[i];
              if (d.error == "spa exist") {
                list.splice(d.key, 1);
                console.log("ANY RESPONSE : " + d.key);
              }
            }
            localStorage.setItem("any", JSON.stringify(list));
            // console.log("ANY RESPONSE : " + JSON.stringify(res.errors));
            // console.log("ANY RESPONSE : " + JSON.stringify(list));
          }
        })
        .catch((err) => {
          console.log("ANY ERROR LOGGER : " + err.message);
        });
    },
  },
};
</script>
<style scoped>
</style>