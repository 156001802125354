import { createApp, h } from 'vue'
import App from './App.vue'
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import './axios';

// Import Components
import { Base, Footer, Navbar, ITable, VueperSlide, VueperSlides } from './components'

// Configure App
const app = createApp({
    render: () => h( App )
});
// Use WaveUI as layout framework
app.use( WaveUI, {});

// Layout Components
app.component('base-app', Base);
app.component('footer-app', Footer);
app.component('nav-app', Navbar);
app.component('vue-slide', VueperSlide);
app.component('vue-slides', VueperSlides);

// Global Components
app.component('i-table', ITable);

app.config.errorHandler = (error) => {
    // Error Handler Here
    console.log(error)
}


// Mount app
app.mount('#app')
