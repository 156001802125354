<template>
  <base-app>
    <a href="#contact" @click="requestCv()">
      <button class="btn-rounded btn btn-outline-info mt-4 px-5">
        Request CV
      </button>
    </a>
    <div class="page-content">
      <!-- about section -->
      <section class="section pt-5" id="about">
        <!-- container -->
        <div class="container text-center">
          <!-- about wrapper -->
          <div class="about">
            <div class="about-caption text-white mt-5 pt-5">
              <h2 class="section-title text-center">About Me</h2>
              <p class="section-subtitle text-info text-center">Who Am I ?</p>
              <p class="mt-5 h4 text-center">
                I am a software developer with proven expertise in using new
                tools and technical development to drive improvement throughout
                software development. Having full life cycle experience in
                html5, css3, javascript, php, flutter, API and SQL based
                environment, along with exceptional analytical, design and
                problem-solving capabilities
                <br />
              </p>
            </div>
          </div>
          <!-- end of about wrapper -->
        </div>
        <!-- end of container -->
      </section>
      <!-- end of about section -->

      <br /><br /><br /><br /><br />
      <!-- service section -->
      <section class="section mt-5" id="service">
        <div class="container text-center mt-5 pt-5">
          <h2 class="section-title">My Services</h2>
          <p class="section-subtitle text-info">What I Do ?</p>
          <!-- row -->
          <div class="row mt-5 pt-5">
            <div class="col-md-6 col-lg-3 mb-4">
              <div class="service-card">
                <div class="body text-white">
                  <img
                    src="assets/img/responsive.svg"
                    alt="Download free bootstrap 4 landing page,
                                          free boootstrap 4 templates, Download free
                                          bootstrap 4.1 landing page, free boootstrap
                                          4.1.1 templates, meyawo Landing page"
                    class="icon"
                  />
                  <p class="title h3">Web App</p>
                  <p class="subtitle">
                    Get responsive website or web app that fit at any device
                    like computer, tablet, phone
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-4">
              <div class="service-card">
                <div class="body text-white">
                  <img
                    src="assets/img/responsive.svg"
                    alt="Download free bootstrap 4 landing page,
                                          free boootstrap 4 templates, Download free
                                          bootstrap 4.1 landing page, free boootstrap
                                          4.1.1 templates, meyawo Landing page"
                    class="icon"
                  />
                  <p class="h3">Mobile App</p>
                  <p class="subtitle">
                    Simplify your work when you organize them in mobile app.
                    This is a fastest way to access data.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-4">
              <div class="service-card">
                <div class="body text-white">
                  <img
                    src="assets/img/responsive.svg"
                    alt="Download
                                          free bootstrap 4 landing page, free
                                          boootstrap 4 templates, Download free
                                          bootstrap 4.1 landing page, free boootstrap
                                          4.1.1 templates, meyawo Landing page"
                    class="icon"
                  />
                  <p class="h3">Desktop App</p>
                  <p class="subtitle">
                    Desktop app work perfect on your computer machine when you
                    work on it.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-4">
              <div class="service-card">
                <div class="body text-white">
                  <img
                    src="assets/img/startup.svg"
                    alt="Download free bootstrap 4 landing page,
                                          free boootstrap 4 templates, Download free
                                          bootstrap 4.1 landing page, free boootstrap
                                          4.1.1 templates, meyawo Landing page"
                    class="icon"
                  />
                  <p class="h3">
                    API Integration
                    <!-- <br>ZRB,TRA,NHIF -->
                  </p>
                  <p class="subtitle">
                    Integrate your business with major payment gateway that help
                    you to interact with government service without using lot of
                    cost.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-4">
              <div class="service-card">
                <div class="body text-white">
                  <img
                    src="assets/img/shipped.svg"
                    alt="Download free bootstrap 4 landing page,
                                          free boootstrap 4 templates, Download free
                                          bootstrap 4.1 landing page, free boootstrap
                                          4.1.1 templates, meyawo Landing page"
                    class="icon"
                  />
                  <p class="h3">Web Hosting</p>
                  <p class="subtitle">
                    Let host your web app, website even API with lowest cost.
                    Supported language is PHP, PYTHON, NODE AND RUBBY
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-4">
              <div class="service-card">
                <div class="body text-white">
                  <img
                    src="assets/img/toolbox.svg"
                    alt="Download free bootstrap 4 landing page,
                                          free boootstrap 4 templates, Download free
                                          bootstrap 4.1 landing page, free boootstrap
                                          4.1.1 templates, meyawo Landing page"
                    class="icon"
                  />
                  <p class="h3">IT Support</p>
                  <p class="subtitle">
                    Get my support about advice, development, maintenance, error
                    fixing and so on.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-4">
              <div class="service-card">
                <div class="body text-white">
                  <img
                    src="assets/img/analytics.svg"
                    alt="Download free bootstrap 4 landing page,
                                          free boootstrap 4 templates, Download free
                                          bootstrap 4.1 landing page, free boootstrap
                                          4.1.1 templates, meyawo Landing page"
                    class="icon"
                  />
                  <p class="h3">Digital Marketing</p>
                  <p class="subtitle">
                    Business without market is nothing, so, we advertise your
                    business, company, organization and get the client.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-4">
              <div class="service-card">
                <div class="body text-white">
                  <img
                    src="assets/img/pencil-case.svg"
                    alt="Download free bootstrap 4 landing page,
                                          free boootstrap 4 templates, Download free
                                          bootstrap 4.1 landing page, free boootstrap
                                          4.1.1 templates, meyawo Landing page"
                    class="icon"
                  />
                  <p class="h3">CPanel Migration</p>
                  <p class="subtitle">
                    I can transfer your current host from one server to us or
                    even to another host provider.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end of row -->
        </div>
      </section>
      <!-- end of service section -->
      <br /><br /><br /><br /><br />
      <!-- <section class="section mt-5 box1" id="portfolio">
        <div class="container text-center mt-5 pt-5">
          <h6 class="section-title mb-3">
            Portfolio<br />( TOP PUBLIC PROJECT )
          </h6>
          <p class="section-subtitle text-info text-center">What I did ?</p>
          <div class="mt-5 pt-5">
            <h3 class="text-left">MOBILE APP</h3>
            <vue-slides
              fractions
              class="no-shadow thumbnails"
              :visible-slides="4"
              slide-multiple
              :gap="3"
              :slide-ratio="1 / 4"
              :dragging-distance="200"
              :breakpoints="{
                600: { visibleSlides: 1, slideMultiple: 1 },
                800: { visibleSlides: 2, slideMultiple: 2 },
                1000: { visibleSlides: 3, slideMultiple: 3 },
              }"
            >
              <vue-slide
                v-for="i in slides.mobile"
                :key="i"
                :title="i.title.toString()"
                :content="i.content"
                :image="i.img"
                :link="i.link"
                openInNew="true"
                @click="appClick(i.title + ' Mobile ')"
              />
            </vue-slides>

            <h3 class="text-left mt-5">WEB APP</h3>
            <vue-slides
              fractions
              class="no-shadow thumbnails"
              :visible-slides="4"
              slide-multiple
              :gap="3"
              :slide-ratio="1 / 4"
              :dragging-distance="200"
              :breakpoints="{
                600: { visibleSlides: 1, slideMultiple: 1 },
                800: { visibleSlides: 2, slideMultiple: 2 },
                1000: { visibleSlides: 3, slideMultiple: 3 },
              }"
            >
              <vue-slide
                v-for="i in slides.web"
                :key="i"
                :title="i.title.toString()"
                :content="i.content"
                :image="i.img"
                :link="i.link"
                openInNew="true"
                @click="appClick(i.title + ' Web ')"
              />
            </vue-slides>
          </div>
        </div>
      </section> -->
      <!-- end of portfolio section -->

      <!-- Contact section -->
      <br />
      <section class="section" id="contact">
        <div class="container text-left mt-5 pt-5">
          <h6 class="section-title mb-3 text-center">Contact Me</h6>
          <p class="section-subtitle text-info text-center">
            Know more about me and my private work
          </p>
          <!-- row -->
          <div class="contact-form bg-white pa0 mt-5 pt-5">
            <w-card content-class="pa6 bg-white">
              <div class="message-box px8">
                <w-transition-fade>
                  <w-alert
                    v-if="form.submitted"
                    success
                    no-border
                    class="my0 text-success"
                  >
                    The form is valid, ready to send it!
                  </w-alert>

                  <w-alert
                    v-else-if="form.valid === false"
                    error
                    outline
                    class="my0 text-danger"
                  >
                    This form has {{ form.errorsCount }} error{{
                      form.errorsCount > 1 ? "s" : ""
                    }}.
                  </w-alert>
                </w-transition-fade>
              </div>

              <w-form
                v-model="form.valid"
                v-model:errors-count="form.errorsCount"
                @validate="onValidate"
                @success="onSuccess"
                class="px8 pt2 pb12"
              >
                <w-flex wrap>
                  <w-select
                    required
                    label="Title"
                    :validators="[validators.titlereq]"
                    :items="titles"
                    v-model="data.title"
                    class="mt3 pa2 md6"
                  ></w-select>
                  <w-input
                    required
                    label="Fullname"
                    :validators="[validators.namereq]"
                    v-model="data.fullname"
                    class="mt3 pa2 md6"
                  >
                  </w-input>
                </w-flex>

                <w-input
                  required
                  type="email"
                  label="Email"
                  :validators="[validators.emailreq]"
                  v-model="data.email"
                  class="mt3 pa2"
                >
                </w-input>

                <w-input
                  required
                  label="Subject"
                  :validators="[validators.subreq]"
                  v-model="data.subject"
                  class="mt3 pa2"
                >
                </w-input>

                <w-textarea
                  required
                  label="Message"
                  :validators="[validators.subreq]"
                  v-model="data.message"
                  class="mt3 pa2"
                >
                </w-textarea>
                <w-flex wrap align-center class="mt7">
                  <!-- <div class="spacer"></div> -->

                  <w-button
                    type="submit"
                    :disabled="form.valid === false"
                    :loading="form.loading"
                    class="pa4 px6"
                    bg-color="success"
                  >
                    SEND
                  </w-button>
                  <w-button
                    bg-color="warning"
                    type="reset"
                    @click="form.submitted = form.sent = false"
                    class="pa4 ml4 px6"
                    md
                  >
                    Reset
                  </w-button>
                </w-flex>
              </w-form>

              <w-notification
                v-model="form.sent"
                success
                transition="bounce"
                absolute
                plain
                round
                bottom
              >
                The form was sent successfully!
              </w-notification>
              <w-notification
                v-model="form.fail"
                error
                transition="bounce"
                absolute
                plain
                round
                bottom
              >
                Fail to send your details, Please try again
              </w-notification>
            </w-card>
          </div>
        </div>
      </section>
    </div>
    <br /><br />
  </base-app>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4, validate as uuidvalid } from "uuid";
export default {
  name: "App",
  components: {},
  data() {
    return {
      analydata: [],
      titles: [
        { label: "Mr" },
        { label: "Mrs" },
        { label: "Dr" },
        { label: "Miss" },
        { label: "Prof" },
      ],
      form: {
        sent: false,
        fail: false,
        submitted: false,
        valid: null,
        errorsCount: 0,
        loading: false,
      },
      validators: {
        titlereq: (value) => !!value || "Title is required",
        namereq: (value) => !!value || "Fullname is required",
        emailreq: (value) => !!value || "Email is required",
        subreq: (value) => !!value || "Subject is required",
        messagereq: (value) => !!value || "Message is required",
      },
      data: {
        title: "",
        fullname: "",
        email: "",
        subject: "",
        message: "",
      },
      slides: {
        mobile: [
          {
            title: "FAST FOREX",
            content: "Mobile App",
            img: "assets/img/ffb.png",
            link: "https://play.google.com/store/apps/details?id=tz.co.fastforex.ffb&hl=en_GB&gl=US",
          },
          {
            title: "ATTAMAYYUZ",
            content: "Mobile App",
            img: "assets/img/attamayyuz.png",
            link: "https://play.google.com/store/apps/details?id=tz.or.attamayyuz",
          },
          {
            title: "COST COLLECTOR",
            content: "Mobile App",
            img: "assets/img/costcollector.png",
            link: "https://play.google.com/store/apps/details?id=com.ismailarmaster.costscollector",
          },
          {
            title: "WIKAHOST",
            content: "Mobile App",
            img: "assets/img/wikahost.png",
            link: "https://play.google.com/store/apps/details?id=com.wikahost",
          },
        ],
        web: [
          {
            title: "ZAN EAGLES TOUS & SAFARIS",
            content: "Web Platform",
            img: "assets/img/zaneagles.png",
            link: "https://zan-eagles-tours-safaris.com",
          },
          {
            title: "FISH MARKET TOURS",
            content: "Web Platform",
            img: "assets/img/fishlogo.png",
            link: "https://fish-market-tours.co.tz/",
          },
          {
            title: "ATTAMAYYUZ",
            content: "Web Platform",
            img: "assets/img/attamayyuz.png",
            link: "https://attamayyuz.or.tz",
          },

          {
            title: "WIKAHOST",
            content: "Web App",
            img: "assets/img/wikahost.png",
            link: "https://wikahost.com",
          },
        ],
      },
    };
  },
  methods: {
    appClick(title) {
      this.setAny(title + " App clicked", "app");
      this.analysis();
    },
    requestCv() {
      this.data.subject = "CV Request";
      this.data.message =
        "Hi Ismail Omar I appreciate your portfolio, Please can I get your CV?";
      this.setAny("Click request button", "cv");
      this.analysis();
    },
    onSuccess() {
      this.setAny("Submit filled form", "formfilled");
      this.form.loading = true;
      const from = localStorage.getItem("ccode") ?? null;
      // setTimeout(() => {
      //   this.form.sent = true;
      //   this.form.submitted = false;
      //   this.form.loading = false;
      // }, 2000);
      var obj = {
        p: "c",
        title: this.data.title,
        fullname: this.data.fullname,
        email: this.data.email,
        subject: this.data.subject,
        message: this.data.message,
        dur: from,
      };
      // console.log("DATA TO SEND : " + JSON.stringify(obj));
      axios
        .post("", obj)
        .then((response) => {
          // console.log("RESPONSE : " + JSON.stringify(response.data));
          const res = response.data;
          if (res.success == 1) {
            this.form.fail = false;
            this.form.sent = true;
            this.form.submitted = false;
            this.form.loading = false;
            this.form.valid = null;
            this.setAny("Submit form filled sent success", "formsuccess");
            this.analysis();
          } else if (res.success == -1) {
            var c = uuidv4();
            this.formFail();
            this.visitors(c);
          } else {
            this.formFail();
          }
        })
        .catch((err) => {
          this.formFail();
          this.setAny("Submit form fail : " + err.message, "formfail");
        });
    },
    onValidate() {
      this.form.sent = false;
      var unfilled = this.form.errorsCount;
      this.form.submitted = unfilled === 0;
      if (unfilled > 0) {
        this.setAny("Submit form with " + unfilled + "error", "formerror");
        this.analysis();
      }
    },
    visitors(c) {
      axios
        .post("", { p: "v", code: c })
        .then((response) => {
          const res = response.data;
          if (res.success == 1) {
            localStorage.setItem("ccode", c);
            this.setAny("Visit portfolio", "visit");
            this.analysis();
            // console.log("VISITOR RESPONSE : " + res.message);
          }
        })
        .catch((err) => {
          this.setAny("Visitor init fail : " + err.message, "visitorerror");
          this.analysis();
        });
    },
    analysis() {
      const from = localStorage.getItem("ccode") ?? null;
      const obj = { p: "a", from: from, data: this.getAny() };
      // console.log("DATA TO SEND : " + JSON.stringify(obj));
      axios.post("", obj).then((response) => {
        const res = response.data;
        if (res.success == 1) {
          // console.log("ANY RESPONSE : " + res);
        } else if (res.success == -1) {
          // console.log("ANY RESPONSE : " + res.message);
          this.visitors(obj.from);
        } else if (res.success == 0) {
          var list = this.getAny();
          for (var i = 0; i < res.errors.length; i++) {
            var d = res.errors[i];
            if (d.error == "spa exist") {
              list.splice(d.key, 1);
              // console.log("ANY RESPONSE : " + d.key);
            }
          }
          localStorage.setItem("any", JSON.stringify(list));
          // console.log("ANY RESPONSE : " + JSON.stringify(res.errors));
          // console.log("ANY RESPONSE : " + JSON.stringify(list));
        }
      });
      // .catch((err) => {
      //   // console.log("ANY ERROR LOGGER : " + err.message);
      // });
    },
    setAny(act, xyz) {
      var c = localStorage.getItem("ccode") ?? null;
      if (c != null) {
        const obj = {
          act: act,
          xyz: xyz,
          spa: uuidv4(),
        };
        var d = localStorage;
        var list = this.getAny();
        list.push(obj);
        d.setItem("any", JSON.stringify(list));
      }
    },
    getAny() {
      var d = localStorage;
      var any = d.getItem("any");
      var list = [];
      if (any == null) {
        d.setItem("any", JSON.stringify(list));
      } else {
        list = JSON.parse(d.getItem("any"));
      }

      return list;
    },
    formFail() {
      this.form.sent = false;
      this.form.fail = true;
      this.form.submitted = false;
      this.form.loading = false;
    },
    ac() {
      var l = localStorage;
      var c = l.getItem("ccode") ?? null;
      if (c == null || !uuidvalid(c)) {
        c = uuidv4();
        this.visitors(c);
      } else {
        // this.setAny("Refresh portfolio",'refresh');
      }
      this.analysis();
    },
  },
  beforeMount() {
    this.ac();
  },
};
</script>

<style>
.thumbnails {
  margin: auto;
  /* max-width: 800px; */
  padding: 40px;
}
.vueperslide {
  background: no-repeat;
  background-size: 60% !important;
}
.vueperslide__content-wrapper {
  background: rgba(0, 0, 0, 0.7);
  color: white;
}
body {
  background-color: #222534;
  /* background-color: rgba(0, 0, 0, 0.1); */
  /*#6F6349*/

  text-align: center;
  /* font-family: 'Shadows Into Light', cursive; */
  font-family: "Shadows Into Light" arial;
  font-size: 16px;
  /* color: white; */
  font-weight: 100;
}

#form > div {
  margin-bottom: 25px;
}

#form > div:last-child {
  margin-top: 10px;
  margin-bottom: 10px;
}

.panel {
  background-color: transparent;
}

.panel-body {
  padding-top: 30px;
  background-color: rgba(2555, 255, 255, 0.3);
}

#particles {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}

/*navbar*/
.animate {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.destacados {
  padding: 70px 0;
  text-align: center;
}

header {
  color: white;
  background: rgba(34, 37, 52, 0.2);
}

.destacados > div:hover > div {
  /* margin-top: -10px; */

  /* transition: 0.5s; */
}

.box1,
.box2 {
  /* See "NOTE 3" */
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 20px;
  margin: 20px auto;
  /*border: 1px solid rgb(200, 200, 200);*/
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px 2px;
  /* background: rgba(34, 37, 52, 0.85); */
  background: rgba(200, 200, 200, 0.1);
  border-radius: 4px;
}

.box1 {
  font-family: "Shadows Into Light", cursive;
}

.box1 h3 {
  position: relative;
  padding: 10px 30px;
  margin: 0 -30px 20px;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  /*background:#cc0000;*/
  /* css3 extras */
  background: -webkit-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(90deg, #2caab3 0%, #2c8cb3 100%);
  /* Standard syntax (must be last) */
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  /* See "NOTE 1" */
  zoom: 1;
  background: #19bc9d !important;
}

.box1 h3:before,
.box1 h3:after {
  content: "";
  position: absolute;
  /* See "NOTE 2" */
  z-index: -1;
  top: 100%;
  left: 0;
  border-width: 0 9px 10px 0;
  border-style: solid;
  border-color: transparent #b7b3b3;
}

.box1 h3:after {
  left: auto;
  right: 0;
  border-width: 0 0 10px 9px;
}

.box2 .icon {
  width: 40px !important;
  height: 40px !important;
  text-align: center;
  align-content: center;
  padding-top: 10px;
  margin: 0 6px;
  margin-bottom: 10px;
}

.block {
  background: rgba(34, 37, 52, 0.9);
  /* background: red; */
  z-index: 1;
}

/* CONTENT SECTION */
.about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}

.about-img-holder {
  min-width: 400px;
  max-width: 400px;
  margin-right: 20px;
}

.about-img-holder .about-img {
  width: 100%;
  margin-bottom: 15px;
}

.about-caption {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 767.98px) {
  .about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .about-img-holder {
    max-width: 220px;
    min-width: 100%;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
  }
}

.service-card {
  position: relative;
  min-height: 230px;
  border: 1px solid #dee2e6;
  overflow: hidden;
  border-radius: 3px;
}

.service-card .body {
  padding: 10px;
}

.service-card .body img.icon {
  margin-top: 40px;
  width: 55px;
  margin-bottom: 25px;
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .service-card .body img.icon {
    -webkit-transition: none;
    transition: none;
  }
}

.service-card .body .subtitle {
  position: absolute;
  bottom: -100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .service-card .body .subtitle {
    -webkit-transition: none;
    transition: none;
  }
}

.service-card:hover .body img.icon {
  margin-top: 5px;
  width: 45px;
  margin-bottom: 15px;
}

.service-card:hover .body .subtitle {
  bottom: 7px;
  opacity: 1;
  visibility: visible;
}

.section .section-title {
  font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1300 - 300)));
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-family: "Baloo Paaji", cursive;
  color: white;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

/*/ portfolio wrapper */
.portfolio-card {
  display: block;
  position: relative;
  overflow: hidden;
}

.portfolio-card .portfolio-card-img {
  width: 100%;
}

.portfolio-card .portfolio-card-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  visibility: hidden;
  opacity: 0;
  background-image: -webkit-linear-gradient(
    bottom,
    #e1dfed 0%,
    rgba(225, 223, 237, 0.7) 99%,
    rgba(225, 223, 237, 0.8) 100%
  );
  background-image: linear-gradient(
    to top,
    #e1dfed 0%,
    rgba(225, 223, 237, 0.7) 99%,
    rgba(225, 223, 237, 0.8) 100%
  );
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
  width: 0;
  height: 0;
  border-radius: 50%;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .portfolio-card .portfolio-card-overlay {
    -webkit-transition: none;
    transition: none;
  }
}

.portfolio-card .portfolio-card-overlay .portfolio-card-caption {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.portfolio-card:hover .portfolio-card-overlay {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

@media (max-width: 767.98px) {
  .portfolio-card {
    margin-bottom: 30px;
  }

  .service-card {
    margin-bottom: 30px;
  }
}

.contact-form {
  padding: 50px;
  margin: 30px 0;
}

.contact-form h1 {
  color: #19bc9d;
  font-weight: bold;
  margin: 0 0 15px;
}

.contact-form .form-control,
.contact-form .btn {
  min-height: 38px;
  border-radius: 2px;
}

.contact-form .form-control:focus {
  border-color: #19bc9d;
}

.contact-form .btn-primary,
.contact-form .btn-primary:active {
  color: #fff;
  min-width: 150px;
  font-size: 16px;
  background: #19bc9d !important;
  border: none;
}

.contact-form .btn-primary:hover {
  background: #15a487 !important;
}

.contact-form .btn i {
  margin-right: 5px;
}

.contact-form label {
  opacity: 0.7;
  color: white;
  text-align: left !important;
}

.contact-form textarea {
  resize: vertical;
}

.hint-text {
  font-size: 15px;
  padding-bottom: 20px;
  opacity: 0.6;
}

.text-info {
  color: #19bc9d !important;
}

.btn-outline-info {
  color: #19bc9d !important;
  border: 1px solid #19bc9d !important;
}

.btn-outline-info:hover {
  background-color: transparent;
}
</style>
